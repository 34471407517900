// |UI| Makes an element drag when subscribed to.

import {
    animationFrameScheduler,
    fromEvent,
    map,
    merge,
    sampleTime,
    switchMap,
    takeUntil,
    tap,
    filter
} from 'rxjs';

function draggable(el, optionalDownTargetEl) {

    const downTarget = optionalDownTargetEl || el;

    downTarget.ondragstart = function (e) {
        e.preventDefault();
        return false;
    };

    const mouseMove$ = fromEvent(window, 'mousemove');
    const mouseUp$ = fromEvent(window, 'mouseup');
    const mouseOut$ = fromEvent(window, 'mouseleave');
    const mouseDown$ = fromEvent(downTarget, 'mousedown').pipe(
        filter(event => {
            const rect = downTarget.getBoundingClientRect();
            const threshold = 20; // Define the size of the bottom right corner area
            const inBottomRightCorner = event.clientX >= (rect.right - threshold) &&
                event.clientY >= (rect.bottom - threshold);
            return !inBottomRightCorner;
        })
    );

    const drag$ = mouseDown$.pipe(
        filter(({button}) => button === 0),
        switchMap(startEvent => {

            const startX = startEvent.clientX;
            const startY = startEvent.clientY;

            const initialTranslate = el.style.transform.match(/translate\((.+)px, (.+)px\)/);
            const initialX = initialTranslate ? parseFloat(initialTranslate[1]) : 0;
            const initialY = initialTranslate ? parseFloat(initialTranslate[2]) : 0;

            return mouseMove$.pipe(
                map(moveEvent => {
                    moveEvent.preventDefault();
                    const deltaX = moveEvent.clientX - startX;
                    const deltaY = moveEvent.clientY - startY;
                    return {
                        x: initialX + deltaX,
                        y: initialY + deltaY
                    };
                }),
                takeUntil(merge(mouseUp$, mouseOut$))
            );
        })
    );

    return drag$.pipe(
        tap(position => {
            el.style.transform = `translate(${position.x}px, ${position.y}px)`;
        })
    );
}

export default draggable;