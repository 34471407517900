// |Array| Returns a iterator to keep cycling through all the elements of an array.

function cycle(arr) {
    var idx = -1;
    return function () {
        if (++idx >= arr.length) { idx = 0; }
        return arr[idx];
    };
}

export default cycle;