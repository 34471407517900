
// |DOM| Get a page position for a pixel offset within a DOMElement accounting for 2D css transformations (and 3D transformations with the exception of perspective that needs more work). Origin of `-1,-1` would be left top, `0,0` is the center of the element.

function getPointInElementPosition(el, offset = new DOMPoint(0, 0), origin = new DOMPoint(0, 0)) {

    // Get info about element and the window
    const computedStyle = window.getComputedStyle(el);
    const boundingClientRect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    offset = DOMPoint.fromPoint(offset);

    // Update the offset to contain also the position on the page
    offset.x += (el.offsetWidth / 2) * origin.x;
    offset.y += (el.offsetHeight / 2) * origin.y;

    const matrixValues = extractMatrixNumbers(computedStyle.transform);

    if (matrixValues) {
        const domMatrix = new DOMMatrix(matrixValues);
        offset = domMatrix.transformPoint(offset);
        offset.x -= matrixValues[4];
        offset.y -= matrixValues[5];

    }

    const centerX = boundingClientRect.left + boundingClientRect.width / 2;
    const centerY = boundingClientRect.top + boundingClientRect.height / 2;
    const centerPoint = new DOMPoint(centerX + scrollLeft, centerY + scrollTop);

    if (matrixValues) {

    }

    offset.x += centerPoint.x;
    offset.y += centerPoint.y;

    return offset
}

function extractMatrixNumbers(transform) {
    const regex = /matrix(?:3d)?\(([^)]+)\)/;
    const matches = transform.match(regex);
    if (matches) {
        return matches[1].split(',').map(parseFloat);
    } else {
        // No transformation matrix found
        return null;
    }
}

export default getPointInElementPosition;