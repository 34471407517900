import { share, map, tap, distinctUntilChanged } from "rxjs";
import pageSizeObservable from "./lib/page-size-observable";

var pageSizeObservable$ = pageSizeObservable();

var breakpoints = require('./all-breakpoints');

export function getBreakpointFor(width) {
    let currentBreakpoint = 'default';
    
    for (let [breakpoint, minWidth] of breakpoints) {
        if (innerWidth >= minWidth) {
            currentBreakpoint = breakpoint;
        } else {
            break;
        }
    }

    return currentBreakpoint;
}

export function getAllBreakpoints() {
    return breakpoints;
}

var latest = 'default';

var widthBreakpointObservable$ = pageSizeObservable$
    .pipe(
        map(_ => window.innerWidth),
        distinctUntilChanged(),
        map(getBreakpointFor),
        tap(v => latest = v),
        share()
    );

export function getWidthBreakpointObservable() {
    return widthBreakpointObservable$;
}

export function getLatestBreakpoint () {
    return latest;
}


export default getWidthBreakpointObservable;