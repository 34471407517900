import domRender from "./lib/dom-render";
import animationFrameObservable from "./lib/animation-frame-observable";
import {
    filter,
    tap,
} from "rxjs";

function connectors(targetEl) {

    var count = 0;
    var defsEl;

    const circleDefinition = [
        'circle',
        {
            cx: 3, cy: 3, r: 3
        }
    ];

    const defsDefinition = [
        'defs',
        null,
        [
            // markerStartDefinition,
            // markerEndDefinition,
            // gradientDefinition
        ],
        {
            onRef: el => defsEl = el
        }
    ];

    const svgDefinition = [
        'svg',
        {
            id: 'connectors',
            width: "100%",
            height: "100%",
            style: {
                pointerEvents: "none",
                width: '100%',
                height: '100%',
                position: "absolute",
                top: 0,
                left: 0,
                'z-index': 0
            }
        },
        [defsDefinition]
    ];

    const svgEl = domRender(svgDefinition, targetEl);

    const updateObserver$ = animationFrameObservable();

    return {
        makeConnector: () => {
            var id = ++count;

            var startColor = "red";
            var endColor = "green";
            var markerStartEl;
            var markerEndEl;
            var stopStartEl;
            var stopEndEl;
            var gradientEl;

            const markerStartDefinition = [
                'marker',
                {
                    id: "circleStart" + id,
                    markerWidth: 6,
                    markerHeight: 6,
                    refX: 3,
                    refY: 3,
                    orient: "auto",
                    fill: startColor,
                    'stroke-linejoin': "round",
                },
                [circleDefinition],
                {
                    onRef: (el) => markerStartEl = el
                }
            ];
        
            const markerEndDefinition = [
                'marker',
                {
                    id: "circleEnd" + id,
                    markerWidth: 6,
                    markerHeight: 6,
                    refX: 3,
                    refY: 3,
                    orient: "auto",
                    fill: endColor,
                    'stroke-linejoin': "round",
                },
                [circleDefinition],
                {
                    onRef: (el) => markerEndEl = el
                }
            ];
        
            const stopStart = [
                'stop',
                {
                    'style': "stop-color: rgb(255, 0, 0)",
                    offset: 0
                },
                [],
                {
                    onRef: el => stopStartEl = el
                }
            ];
        
            const stopEnd = [
                'stop',
                {
                    'style': "stop-color: rgb(255, 255, 0)",
                    offset: 1
                },
                [],
                {
                    onRef: el => stopEndEl = el
                }
            ]
        
            const gradientDefinition = [
                'linearGradient',
                {
                    id: 'gradient' + id,
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 0,
                    gradientUnits: 'userSpaceOnUse'
                },
                [
                    stopStart,
                    stopEnd
                ],
                {
                    onRef: el => gradientEl = el
                }
            ];

            const pathControls = [
                'M', 0, 0, 'C', 0, 0, 0, 0, 0, 0
            ];

            var props = {
                fill: "none",
                stroke: 'url("#gradient' + id + '")',
                "stroke-width": "1",
                "marker-start": "url(#circleStart" + id + ")",
                "marker-end": "url(#circleEnd" + id + ")",
            };

            var pathEl;
            var needsUpdate = true;

            var updateSubscription = updateObserver$
                .pipe(
                    filter(_ => needsUpdate),
                    tap(_ => needsUpdate = false)
                )
                .subscribe(_ => {
                    props.d = pathControls.join(' ');

                    if (!pathEl) {
                        // Create the line
                        const pathDefinition = [
                            'path',
                            props
                        ];
                        pathEl = domRender(pathDefinition, svgEl);

                        domRender(markerStartDefinition, defsEl);
                        domRender(markerEndDefinition, defsEl);
                        domRender(gradientDefinition, defsEl);

                    } else {
                        Object.keys(props).forEach(key => {
                            pathEl.setAttribute(key, props[key]);
                        });
                    }

                    stopStartEl.setAttribute('style', 'stop-color: ' + startColor);
                    stopEndEl.setAttribute('style', 'stop-color: ' + endColor);

                    markerStartEl.setAttribute('fill', startColor);
                    markerEndEl.setAttribute('fill', endColor);

                    gradientEl.setAttribute('x1', pathControls[1])
                    gradientEl.setAttribute('y1', pathControls[2])
                    gradientEl.setAttribute('x2', pathControls[8])
                    gradientEl.setAttribute('y2', pathControls[9])
                });

            var methods = {
                dispose: () => {
                    updateSubscription.unsubscribe();
                    if (pathEl) {
                        pathEl.remove();
                    }
                },
                from: (x, y, cx, cy, color) => {
                    pathControls[1] = x;
                    pathControls[2] = y;
                    pathControls[4] = cx;
                    pathControls[5] = cy;
                    startColor = color;
                    needsUpdate = true;
                    return methods;
                },
                to: (x, y, cx, cy, color) => {
                    pathControls[6] = cx;
                    pathControls[7] = cy;
                    pathControls[8] = x;
                    pathControls[9] = y;
                    endColor = color;
                    needsUpdate = true;
                    return methods;
                }
            }

            return methods;
        }
    }
}

export default connectors;