import { Observable, share } from "rxjs";

function intersectionObservable(el, options = {}) {
    return new Observable(subscriber => {
        // Create the intersection observer
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                subscriber.next(entry);
            });
        }, options);

        // Start observing the provided element
        observer.observe(el);

        // Cleanup function when the observable is unsubscribed
        return () => observer.disconnect();
    }).pipe(
        share()
    );
}

export default intersectionObservable;
