import { Observable, share } from "rxjs";

function animationFrameObservable() {
    return new Observable(subscriber => {
        let startTime = performance.now();
        let lastUpdate = startTime;

        function animate() {
            const currentTime = performance.now();
            const deltaTime = currentTime - lastUpdate;
            const time = currentTime - startTime;
            lastUpdate = currentTime;

            subscriber.next([deltaTime, time]);

            // Continue the animation loop
            requestAnimationFrame(animate);
        }

        // Start the animation loop
        requestAnimationFrame(animate);

        // Teardown logic when the observable is unsubscribed
        return () => {
            // No specific teardown needed for requestAnimationFrame
        };
    }).pipe(
        share()
    );
}

export default animationFrameObservable;
