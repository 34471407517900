// |RxJS:Dom| Watch when the content of a webpage changes size. For example, when the window is resized or when the content of a page makes it taller. Useful for calling `render` on any views that are bound to webpage positions.

import {
    fromEvent,
    merge,
    Observable,
    debounceTime,
    animationFrameScheduler,
    share
} from "rxjs";

function pageSizeObservable() {

    // Observable for window resize events
    const resizeObservable = fromEvent(window, 'resize');

    // Observable for content resize using ResizeObserver
    const contentResizeObservable = new Observable(subscriber => {
        const resizeObserver = new ResizeObserver(entries => {
            subscriber.next(entries);
        });
        resizeObserver.observe(document.body);

        // Cleanup function
        return () => resizeObserver.disconnect();
    });

    // Merge the two observables and debounce to avoid excessive calls
    const combinedObservable = merge(resizeObservable, contentResizeObservable).pipe(
        debounceTime(16, animationFrameScheduler), // Adjust the debounce time as needed,
        share()
    );

    return combinedObservable; 
}

export default pageSizeObservable;