import { fromEventPattern, of, switchMap, filter, debounceTime, distinctUntilChanged } from 'rxjs';

// Helper function to create IntersectionObserver observable
function createInViewObservable(element) {
    return fromEventPattern(
        handler => {
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => handler(entry.isIntersecting));
            });
            observer.observe(element);
            return () => observer.disconnect();
        },
        handler => { }
    );
}

// Create the dwell observable
function createDwellObservable(element, dwellTime = 1000) {
    return createInViewObservable(element).pipe(
        distinctUntilChanged(),
        switchMap(isInView => isInView ? of(isInView).pipe(debounceTime(dwellTime)) : of(false)),
        filter(isInView => isInView)
    );
}

export default createDwellObservable;
