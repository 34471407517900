import { fromEvent, defer, Observable, animationFrameScheduler, switchMap, map, startWith, observeOn, filter } from 'rxjs';

function getVideoPixelsObservable(videoEl) {
    // Function to create and return the canvas context
    function createCanvasContext(videoEl) {
        const canvas = document.createElement('canvas');
        document.body.appendChild(canvas);
        canvas.style.display = 'none'; // Hide the canvas element
        canvas.width = 50;
        canvas.height = 50;
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        return { canvas, ctx };
    }

    // Function to get the pixel color
    function getPixelColor(ctx) {
        return () => {
            // Draw the current video frame to the canvas
            ctx.drawImage(videoEl, 0, 0, ctx.canvas.width, ctx.canvas.height);

            // Calculate the coordinates for the left side of the video, halfway up
            const left = 4;
            const right = ctx.canvas.width - 4;
            const y = Math.floor(ctx.canvas.height / 2);

            // Get the pixel data at the calculated coordinates
            return [
                ctx.getImageData(left, y, 1, 1).data,
                ctx.getImageData(right, y, 1, 1).data
            ]
        };
    }

    // Observable to repeatedly call the function using requestAnimationFrame
    const videoFrame$ = defer(() => {
        const frame = (timestamp, observer) => {
            if (!videoEl.paused && !videoEl.ended) {
                observer.next(timestamp);
                requestAnimationFrame((t) => frame(t, observer));
            }
        };

        return new Observable(observer => {
            requestAnimationFrame((t) => frame(t, observer));
        });
    }).pipe(observeOn(animationFrameScheduler));

    return new Observable(subscriber => {
        // Create the canvas and get the context
        const { canvas, ctx } = createCanvasContext(videoEl);

        // IntersectionObserver to check if the video element is visible
        const intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // When the video element is visible, subscribe to the frame observable
                    const subscription = fromEvent(videoEl, 'play').pipe(
                        switchMap(() => videoFrame$),
                        map(getPixelColor(ctx)),
                        startWith([
                            [0, 0, 0, 0],
                            [0, 0, 0, 0]
                        ])
                    ).subscribe({
                        next: pixelData => subscriber.next(pixelData),
                        error: err => subscriber.error(err),
                        complete: () => {
                            // Cleanup canvas element when the observable completes
                            document.body.removeChild(canvas);
                            subscriber.complete();
                        }
                    });

                    // Return the teardown logic for the observable
                    return () => {
                        subscription.unsubscribe();
                        document.body.removeChild(canvas);
                    };
                }
            });
        });

        // Observe the video element
        intersectionObserver.observe(videoEl);

        // Return the teardown logic for the observer
        return () => {
            intersectionObserver.disconnect();
            document.body.removeChild(canvas);
        };
    });
}

export default getVideoPixelsObservable;
