// |Client| Create dom elements out of an input object

// The input object is in the format:
//    - `[name:string, props:Object, children, options:Object]`, or
//    - `text:string`
// `children` can be another input object
// Options can be:
//    { onRef:function(el:DOMElement) } // Returns a reference to the dom element that is created

function domRender(inputObject, target) {
    function createElement(obj) {
        if (typeof obj === 'string') {
            return document.createTextNode(obj);
        }

        const [name, props, children, options] = obj;

        let el;
        const svgTags = ['svg', 'circle', 'rect', 'path', 'line', 'polyline', 'polygon', 'text', 'marker', 'defs', 'linearGradient', 'stop'];

        if (svgTags.includes(name)) {
            el = document.createElementNS('http://www.w3.org/2000/svg', name);
        } else {
            el = document.createElement(name);
        }

        if (props) {
            for (let key in props) {
                if (props.hasOwnProperty(key)) {
                    if (typeof props[key] === 'object') {
                        if (typeof el[key] !== 'object') {
                            el[key] = {};
                        }
                        Object.assign(el[key], props[key]);
                    } else {
                        el.setAttribute(key, props[key]);
                    }
                }
            }
        }

        if (children) {
            if (Array.isArray(children)) {
                children.forEach(child => {
                    el.appendChild(createElement(child));
                });
            } else {
                el.appendChild(createElement(children));
            }
        }

        if (options && options.onRef) {
            options.onRef(el);
        }

        return el;
    }

    const element = createElement(inputObject);
    target.appendChild(element);

    return element;
}

export default domRender;
