import { fromEvent, animationFrameScheduler, interval,  map, switchMap, pairwise, throttleTime, distinctUntilChanged } from 'rxjs';

function scrollVelocity() {

    // Function to get the current scroll position
    const getScrollPosition = () => window.scrollY || document.documentElement.scrollTop;

    // Observable that emits scroll positions at a fixed frame rate
    const scrollPosition$ = fromEvent(window, 'scroll').pipe(
        throttleTime(0, animationFrameScheduler), // Ensures emissions happen at animation frame rate
        map(getScrollPosition),
        distinctUntilChanged() // Only emit when scroll position changes
    );

    // Observable that calculates the scroll velocity
    const scrollVelocity$ = scrollPosition$.pipe(
        pairwise(), // Emit previous and current scroll position as a pair
        map(([prev, curr]) => curr - prev), // Calculate the difference (velocity)
        switchMap(velocity =>
            interval(1000 / 60).pipe( // Emit the velocity at a fixed frame rate (e.g., 60fps)
                map(() => velocity)
            )
        )
    );

    return scrollVelocity$;

}

export default scrollVelocity;