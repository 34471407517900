import { fromEvent, interval, merge, takeUntil, switchMap, animationFrameScheduler, tap } from 'rxjs';

function shoogle(element) {

    const mouseEnter$ = fromEvent(element, 'mouseenter');
    const mouseLeave$ = fromEvent(element, 'mouseleave');

    const hover$ = merge(mouseEnter$, mouseLeave$);
    const interval$ = interval(50, animationFrameScheduler);

    hover$
        .pipe(
            switchMap(event => {
                if (event.type === 'mouseenter') {
                    return interval$.pipe(
                        takeUntil(mouseLeave$),
                        tap(() => {
                            element.style.fontStyle = element.style.fontStyle === 'normal' ? 'italic' : 'normal';
                        })
                    );
                } else {
                    return interval(0, animationFrameScheduler).pipe(
                        tap(() => {
                            element.style.fontStyle = 'normal';
                        })
                    );
                }
            })
        )
        .subscribe();
}

export default shoogle;

