// |RxJS:String| A typewriter effect that emits characters to add and remove. 

import {
    animationFrameScheduler,
    concat,
    concatMap,
    delay,
    expand,
    from,
    of,
    switchMap,
    map,
    tap,
    interval,
} from "rxjs";
import cycle from "./cycle";
import randomBetween from "./random-between";

function makeTypewriter({ el, addCharacter, removeCharacter, strings, startWith = '', loop = true, delayStart = 0, onFinishString = () => { } }) {

    var stringCount = 0;

    var addCharacter = addCharacter || function (character) {
        // Add character to input placeholder
        if (el.innerText.endsWith("|")) {
            el.innerText = el.innerText.slice(0, -1) + character + "|";
        } else {
            el.innerText = el.innerText + character + "|";
        }

        // Return null to skip internal adding of dom node
        return null;
    }

    var removeCharacter = removeCharacter || function ({ character }) {
        if (el.innerText) {
            if (el.innerText.endsWith("|")) {
                el.innerText = el.innerText.slice(0, -2) + "|";
            } else {
                el.innerText = el.innerText.slice(0, -1) + "|";
            }
        }
    }

    // Helper function to cycle through the provided strings
    const stringCycler = cycle(strings);

    // Function to create the typewriter effect for a given string
    const createTypewriterEffect$ = (currentString, deleteOnly = false) => {
        // Observable to handle adding characters one by one
        const countUp$ = from(currentString).pipe(
            concatMap((char, idx) => {
                var delayInterval;

                switch (char) {
                    case ' ': delayInterval = randomBetween(100, 200); break;
                    case '…': delayInterval = randomBetween(300, 400); break;
                    default: delayInterval = randomBetween(40, 70);
                }
                if (idx === 0) {
                    delayInterval = 0;
                }

                return of(char).pipe(
                    delay(delayInterval, animationFrameScheduler), // Different random delay if the character is a space
                    tap(() => addCharacter(char)) // Add character
                );
            }),
            tap({
                complete: () => {
                    onFinishString(++stringCount);
                }
            })
        );

        // Observable to handle removing characters one by one in reverse order
        const countDown$ = from(currentString.split('').reverse()).pipe(
            concatMap((char, idx) =>
                of(char).pipe(
                    delay(randomBetween(10, 40), animationFrameScheduler), // Random delay between 10-40ms
                    tap(() => removeCharacter(char)) // Remove character
                )
            )
        );

        // Observable for a long pause after typing the string
        const longPause$ = of(null).pipe(delay(2000));
        // Observable for a short pause before starting to type the next string
        const shortPause$ = of(null).pipe(delay(400));

        if (deleteOnly) {
            // Concatenating the observables to create the full typewriter effect sequence
            return concat(longPause$, countDown$, shortPause$);
        } else {
            if (!loop) {
                // This is a hack to allow for 'strings' of one length
                return concat(countUp$);
            } else {
                // Concatenating the observables to create the full typewriter effect sequence
                return concat(countUp$, longPause$, countDown$, shortPause$);
            }
        }

    };

    var typewriter$;

    if (loop) {
        typewriter$ = of(null).pipe(
            expand(() => {
                const currentString = stringCycler();
                return createTypewriterEffect$(currentString).pipe(
                    concatMap(() => of(null)) // Ensures the sequence completes before cycling to the next string
                );
            }, 1) // Controls the concurrency level of the expand operator
        );
    } else {
        const currentString = stringCycler();
        typewriter$ = createTypewriterEffect$(currentString).pipe(
            concatMap(() => of(null)) // Ensures the sequence completes before cycling to the next string,
        );
    }

    // If we should startWith a string we need to delete it first
    if (startWith) {
        typewriter$ = concat(
            createTypewriterEffect$(startWith, true).pipe(
                concatMap(() => of(null)) // Ensures the sequence completes before cycling to the next string
            ),
            typewriter$
        );
    }


    // Add the delay
    return typewriter$.pipe(delay(delayStart));
};

export default makeTypewriter;
