// |UI| Makes an element resizable when subscribed to

import {
    animationFrameScheduler,
    filter,
    fromEvent,
    map,
    merge,
    sampleTime,
    switchMap,
    takeUntil,
    tap
} from 'rxjs';

function resizable(el, options = {}) {
    const {
        optionalDownTargetEl = el,
        preserveAspect = true,
        widthOnly = false
    } = options;

    const downTarget = optionalDownTargetEl;

    downTarget.ondragstart = function (e) {
        e.preventDefault();
        return false;
    };

    const mouseMove$ = fromEvent(window, 'mousemove');
    const mouseUp$ = fromEvent(window, 'mouseup');
    const mouseOut$ = fromEvent(window, 'mouseleave');
    const mouseDown$ = fromEvent(downTarget, 'mousedown').pipe(
        filter(event => {
            const rect = downTarget.getBoundingClientRect();
            const threshold = 20; // Define the size of the bottom right corner area
            const inBottomRightCorner = event.clientX >= (rect.right - threshold) &&
                event.clientY >= (rect.bottom - threshold);
            return inBottomRightCorner;
        })
    );

    const resize$ = mouseDown$.pipe(
        switchMap(startEvent => {
            const startX = startEvent.clientX;
            const startY = startEvent.clientY;

            const startWidth = el.offsetWidth;
            const startHeight = el.offsetHeight;

            const aspectRatio = startWidth / startHeight;

            return mouseMove$.pipe(
                map(moveEvent => {
                    moveEvent.preventDefault();
                    const deltaX = moveEvent.clientX - startX;
                    const deltaY = moveEvent.clientY - startY;

                    let newWidth = startWidth + deltaX;
                    let newHeight = startHeight + deltaY;

                    if (widthOnly) {
                        newHeight = 0;
                    } else if (preserveAspect) {
                        if (newWidth / newHeight > aspectRatio) {
                            newHeight = newWidth / aspectRatio;
                        } else {
                            newWidth = newHeight * aspectRatio;
                        }

                    }

                    return {
                        width: Math.floor(newWidth),
                        height: Math.floor(newHeight),
                    };
                }),
                takeUntil(merge(mouseUp$, mouseOut$))
            );
        })
    );

    return resize$.pipe(
        tap(size => {
            el.style['max-width'] = `${size.width}px`;
            if (size.height) {
                el.style.height = `${size.height}px`;
            }
            el.style.width = `${size.width}px`;

            //el.style.transform = `translate(${size.translateX}px, ${size.translateY}px)`;
        })
    );
}

export default resizable;
